<template>
    <div class="dashboard">
      <dashboard-charts />
      <!-- <dashboard-info-block /> -->
    </div>
  </template>
  
  <script>
  import DashboardCharts from './credentialCharts';
  
  export default {
    name: 'Statistics',
    components: {
      DashboardCharts,
    },
  };
  </script>
  
  <style lang="scss">
    .row-equal .flex {
      .va-card {
        height: 100%;
      }
    }
  
    .dashboard {
      .va-card {
        margin-bottom: 0 !important;
      }
    }
  </style>
  